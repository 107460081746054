<template>
  <div class="bx-flooding bx-flooding--footer">
    <footer class="bx-footer bx-typo--footer">
      <div class="bx-footer__wrapper">
        <div class="bx-grid">
          <NewsletterSubscription usage="footer"
                                  headline="Abonniere unseren Newsletter" />
          <SeoHeadline />
          <SocialLinks menu-type="footer" />
        </div>
      </div>
      <div class="bx-footer__menu">
        <div class="bx-grid">
          <MenuList :menu="footerData.menu"
                    menu-type="footer" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MenuList from '../../../components/menu/MenuList.vue'
import SeoHeadline from '../../../components/page-footer/SeoHeadline.vue'
import NewsletterSubscription from '../../../components/shared/NewsletterSubscription.vue'
import SocialLinks from '../../../components/shared/SocialLinks.vue'

export default {
  components: {
    MenuList,
    SeoHeadline,
    SocialLinks,
    NewsletterSubscription
  },
  props: {
    footerData: {
      type: Object,
      default: () => { return {} }
    }
  },
  mounted () {
    if (!import.meta.env.SSR) {
      const articleNode = document.getElementsByClassName('bx-page--article')

      // check for article page
      if (articleNode.length > 0) {
        const firstParagraph = document.querySelector('.bx-paragraph-list .bx-js-text-0-1 .bx-paragraph-list__html p') ||
                document.querySelector('.bx-paragraph-list .bx-js-paragraph-collection-0-1 .bx-js-text-1-1 .bx-paragraph-list__html p')

        if (firstParagraph !== null) {
          const innerHtml = firstParagraph.innerHTML
          const isFirstSignChar = /^[a-z0-9]/i
          // and only first char big, if its really a char or number and no markup or something else
          if (innerHtml.match(isFirstSignChar)) {
            firstParagraph.innerHTML = '<span class="bx-maennersache-first-big">' + innerHtml.slice(0, 1) + '</span>' + innerHtml.slice(1)
          }
        }
      }
    }
  }
}
</script>
