// @ts-ignore no types here
import vueApp from '@xcel-next/rendering/src/apps/maennersache/App.vue'

import { getMain } from '../../shared/ssr/main'

import type { SSRContext } from '@xcel-next/rendering-tools'

export function createApp (ssrContext?: SSRContext) {
  return getMain(vueApp, ssrContext)
}
